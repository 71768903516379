// src/screens/team.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { AuthChip, BankChip, GodlikeChip } from "../components/Common";
import CustomLinearProgress, {
  CustomToolbar,
  OvalButton,
  CustomMessage,
} from "../components/Common";
import Modal from "../components/Modal";
import { useData } from "../components/DataProvider";

const Team = () => {
  const { currentUser } = useAuth();
  const { team, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userID, setUserID] = useState(null);
  const [caller, setCaller] = useState(null);

  const authHierarchy = { Admin: 3, User: 2, Guest: 1, "": 0 };

  const closeModal = () => {
    setUserID("");
    setIsModalOpen(false);
  };

  const handleCreate = () => {
    // console.log("Creating new user");
    setCaller("team-add");
    setIsModalOpen(true);
  };

  const handleEdit = (id) => {
    // console.log(`Editing item with ID: ${id}`);
    setUserID(id);
    setCaller("team-edit");
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    // console.log(`Deleting item with ID: ${id}`);
    setUserID(id);
    setCaller("team-delete");
    setIsModalOpen(true);
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 100,
    },
    {
      field: "username",
      headerName: t("Username"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "auth",
      headerName: t("Authority Level"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.row.auth === "GODLIKE" ? (
            <GodlikeChip {...params} value="GODLIKE" />
          ) : (
            <AuthChip {...params} />
          )}
        </div>
      ),
    },
    {
      field: "site",
      headerName: t("Site"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          {Array.isArray(params.value) ? (
            params.value.map((item, index) => {
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              return <BankChip key={index} value={item} color="warning" />;
            })
          ) : (
            <BankChip value={params.value} />
          )}
        </div>
      ),
    },
    {
      field: "timestamp",
      headerName: t("Last Login"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "last_ip",
      headerName: t("Last Login IP"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "firm",
      headerName: t("Firms"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          {Array.isArray(params.value) ? (
            params.value.map((item, index) => {
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              return <BankChip key={index} value={item} color="info" />;
            })
          ) : (
            <BankChip value={params.value} />
          )}
        </div>
      ),
    },
    {
      field: "process",
      headerName: t("Process"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => (
        <div>
          {authHierarchy[currentUser.auth] > authHierarchy[params.row.auth] && (
            <>
              <OvalButton
                label={t("Edit")}
                color="#2196f3"
                onClick={() => handleEdit(params.row.id)}
              />
              <OvalButton
                label={t("Delete")}
                color="#f44336"
                onClick={() => handleDelete(params.row.id)}
              />
            </>
          )}
          {!(
            authHierarchy[currentUser.auth] > authHierarchy[params.row.auth]
          ) && <div>{t("No action available")}</div>}
        </div>
      ),
    },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  if (currentUser.auth !== "Guest") {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="15px"
        >
          <Header title={t("TEAM")} subtitle={t("All team members")} />
          {isModalOpen && (
            <Modal
              onClose={closeModal}
              userID={userID}
              caller={caller}
              onFeedback={handleFeedback}
            />
          )}
          <CustomMessage
            open={openMessage}
            onClose={handleMessageClose}
            message={message}
          />
          <Box>
            {(currentUser.auth === "Admin" ||
              currentUser.auth === "GODLIKE") && (
              <Button
                sx={{
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                  margin: "0 10px 0 0",
                  ...(theme.palette.mode === "dark"
                    ? {
                        backgroundColor: "#13AD23",
                      }
                    : { backgroundColor: "#2BDF28" }),
                }}
                onClick={handleCreate}
              >
                <PersonAddIcon sx={{ mr: "10px" }} />
                {t("Add New User")}
              </Button>
            )}
          </Box>
        </Box>

        <Box
          m="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          //height="100vh" /* Full height of the viewport */
          min-height="0" /* Ensure it can shrink */
          width="99%"
          sx={{
            "& .MuiDataGrid-root": {
              fontSize: "16px",
              ...(theme.palette.mode === "dark"
                ? {
                    border: "1px solid rgba(62, 67, 150, 1)",
                  }
                : { border: "1px solid rgba(232, 93, 4, 1)" }),
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              fontSize: "16px",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "1.2rem",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              ...(theme.palette.mode === "dark"
                ? {
                    backgroundColor: colors.blueAccent[700],
                  }
                : { backgroundColor: colors.orangeAccent[800] }),
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-cell:hover": {
              ...(theme.palette.mode === "dark"
                ? {
                    color: colors.blueAccent[500],
                  }
                : { color: colors.orangeAccent[500] }),
            },
          }}
        >
          <DataGrid
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            }}
            checkboxSelection={false}
            rows={team}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: CustomLinearProgress,
            }}
            loading={loading}
            pageSize={5}
            autoHeight
            autoWidth
            autoPageSize
            disableSelectionOnClick
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="15px"
        >
          <Header
            title={t("403")}
            subtitle={t("You don't have permission to see this page")}
          />
        </Box>
      </Box>
    );
  }
};

export default Team;
