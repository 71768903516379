// src/components/Common.jsx

import React from "react";
import { LinearProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  Box,
  Chip,
  Button,
  Snackbar,
  IconButton,
  Typography,
} from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/ReportProblemOutlined";

export function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      />
      <GridToolbarFilterButton
        slotProps={{
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      />
      <GridToolbarDensitySelector
        slotProps={{
          tooltip: { title: "Change density" },
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarExport
        slotProps={{
          tooltip: { title: "Export Data" },
          button: {
            variant: "outlined",
            color: "secondary",
            padding: "12px 24px", // Adjust padding
            fontSize: "18px", // Adjust font size
            margin: "5px", // Adjust margin if needed
          },
        }}
      />
    </GridToolbarContainer>
  );
}

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  "& .MuiLinearProgress-bar": {
    ...(theme.palette.mode === "dark"
      ? {
          backgroundColor: "#4C0AC6", // Your desired color
        }
      : {
          backgroundColor: "#E85D04", // Your desired color
        }),
  },
  backgroundColor: "#FAA307", // Background color of the progress bar
}));

// const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
//   "& .MuiLinearProgress-bar": {
//     ...(theme.palette.mode === "dark"
//       ? {
//           backgroundColor: "#4C0AC6", // Your desired color
//         }
//       : {
//           backgroundColor: "#ff6f61", // Your desired color
//         }),
//   },
//   backgroundColor: "#ffcccb", // Background color of the progress bar
// }));

export const OvalButton = ({ onClick, label, color, variant }) => (
  <Button
    onClick={onClick}
    variant={variant ? variant : "contained"}
    color={"success"}
    style={
      variant
        ? {
            borderRadius: 999,
            minWidth: 36,
            padding: "8px 16px",
            backgroundColor: color,
            color: "white", // Text color
            marginRight: 8,
          }
        : {
            borderRadius: 999,
            minWidth: 36,
            padding: "8px 16px",
            backgroundColor: color,
            color: "white", // Text color
            marginRight: 8,
          }
    }
  >
    {label}
  </Button>
);

const CustomChip = styled(Chip)(({ theme }) => ({
  backgroundColor: "#FFBB33",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#555555",
    color: "#ffffff",
  },
}));

export const GodlikeChip = (params) => {
  const { value } = params;
  return (
    <CustomChip variant="outlined" label={value} style={{ fontSize: 20 }} />
  );
};

// Custom cell renderer for status using Chip
export const StatusChip = (params, color) => {
  const { value } = params;

  return (
    <Chip
      label={value}
      color={value === "Waiting" ? "success" : "error"}
      variant="outlined"
      style={{ fontSize: 16 }}
    />
  );
};

// Custom cell renderer for status using Chip
export const AuthChip = (params) => {
  const { value } = params;

  return (
    <Chip
      label={value}
      color={
        value === "Admin" || value === "All"
          ? "info"
          : value === "User"
          ? "success"
          : value === "Guest"
          ? "warning"
          : "error"
      }
      variant="outlined"
      style={{ fontSize: 16 }}
    />
  );
};

export const BankChip = ({ key, value, color }) => {
  // const theme = useTheme();
  // console.log(key, value, color);

  let chipColor;
  switch (key) {
    case 0:
      chipColor = "success";
      break;
    case 1:
      chipColor = "warning";
      break;
    case 2:
      chipColor = "primary";
      break;
    case 3:
      chipColor = "error";
      break;
    default:
      chipColor = "default";
  }

  return (
    <Chip
      label={value}
      color={color || chipColor}
      variant="outlined"
      style={{
        fontSize: 16,
        margin: 5,
        // color: theme.palette[chipColor].contrastText,
        // borderColor: theme.palette[chipColor].main,
      }}
    />
  );
};

export const CustomMessage = ({ theme, open, onClose, message, interval }) => {
  const { t } = useTranslation();
  // const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const snackbarAction = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleMessageClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={onClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // Snackbar colors based on the theme
  const snackbarStyle = {
    // ...(theme.palette.mode === "dark"
    //   ? {
    //       backgroundColor: "#333", // Your desired color
    //     }
    //   : {
    //       backgroundColor: "#fff", // Your desired color
    //     }),
    // color: prefersDarkMode ? "#fff" : "#000", // Dark mode or light mode text color
    backgroundColor: "#333",
    display: "flex",
    alignItems: "center",
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={interval || 5000}
      onClose={onClose}
      message={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {message === t("Operation Successfull!") ? (
            <>
              <CheckIcon sx={{ marginRight: 1, color: "green" }} />
              <Typography sx={{ color: "green" }}>{message}</Typography>
            </>
          ) : (
            <>
              <ErrorIcon sx={{ marginRight: 1, color: "red" }} />
              <Typography sx={{ color: "red" }}>{message}</Typography>
            </>
          )}
        </Box>
      }
      action={snackbarAction}
      sx={snackbarStyle}
    />
  );
};

export default CustomLinearProgress;
