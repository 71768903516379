// src/components/firebaseConfig.jsx

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBEdRqw-dSWuWatIzmINRUtQwU6DOdvlMY",
//   authDomain: "react-firebase-test-9b7d3.firebaseapp.com",
//   projectId: "react-firebase-test-9b7d3",
//   storageBucket: "react-firebase-test-9b7d3.appspot.com",
//   messagingSenderId: "684664960366",
//   appId: "1:684664960366:web:eefc39aab94c918fbdbd7f",
//   measurementId: "G-PKXH502RVC",
// };

// Ultrahavale
const firebaseConfig = {
  apiKey: "AIzaSyD80jq5ua4E3rp9OtJe-ik1VF1bKGtc0TY",
  authDomain: "ultrahavale-app.firebaseapp.com",
  projectId: "ultrahavale-app",
  storageBucket: "ultrahavale-app.appspot.com",
  messagingSenderId: "816793399525",
  appId: "1:816793399525:web:0fe22eb7408cb025b3e40c",
  measurementId: "G-T5X89FTYE8",
};

// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { firestore, storage, auth };
