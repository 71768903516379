/* eslint-disable react-hooks/exhaustive-deps */
// src/comnponents/Modal.js

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../theme";
import {
  Box,
  Grid,
  Button,
  Autocomplete,
  TextField,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import {
  getUserDataByUID,
  addUserData,
  updateUserData,
  deleteUserByUID,
  addBankAccount,
  deleteBankAccountByIBAN,
  investProcessDone,
  investProcessCancelled,
  withdrawalProcessDone,
  withdrawalProcessCancelled,
  getAllBanks,
  getAllFirms,
  getAllSites,
  addFirm,
  addSite,
  deleteFirm,
  deleteSite,
} from "./fsFunctions";
import { styled } from "@mui/material/styles";
import IBANInput, { ibanError, ibanValue } from "./IBANInput";
// Icons
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloseIcon from "@mui/icons-material/Close";
// Styles
import "../styles.css";
import "./Modal.css";
// import { useInfo } from "./InfoContext";
// Images
import emptyPhoto from "../assets/no_image.png";

const Modal = ({
  currentUser,
  onClose,
  userID,
  caller,
  extParam,
  onFeedback,
}) => {
  const theme = useTheme();
  // const info = useInfo();
  const [bankNames, setBankNames] = useState([]);
  const [firmNames, setFirmNames] = useState([]);
  const [siteNames, setSiteNames] = useState([]);
  // eslint-disable-next-line
  const colors = tokens(theme.palette.mode);
  const [userData, setUserData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const [profilePicture, setProfilePicture] = useState(null);
  const [errors, setErrors] = useState({});
  const [firmData, setFirmData] = useState("");
  const [siteData, setSiteData] = useState("");
  const [userFormData, setUserFormData] = useState({
    auth: "",
    email: "",
    firm: [],
    last_ip: "",
    last_login: "",
    password: "",
    process: "",
    profilePictureURL: "",
    site: [],
    username: "",
    // uid: "",
  });
  const [bankFormData, setBankFormData] = useState({
    added: "",
    bank: null,
    card_holder: "",
    firm: [],
    iban: "",
    id: 0,
    limit_min: 0,
    limit_max: 0,
    logoURL: "",
    process: "",
    transaction_limit: 0,
  });
  const [cancelReason, setCancelReason] = useState("");

  // Toggle modal open/close
  const closeModal = () => {
    setIsOpen(false);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // Apply "open" class based on modal state
  const modalClassName = `modal ${isOpen ? "open" : ""}`;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (caller === "team-add" || caller === "team-edit") {
      const fetchUserData = async () => {
        if (userID) {
          const data = await getUserDataByUID(userID);
          setUserData(data);
          setUserFormData({
            firm: data.firm,
            profilePictureURL: data.profilePictureURL,
            site: data.site,
          });
          // console.log(data);
        }
      };
      fetchUserData();
    }
  }, [userID]);

  // Gather all bank names and firms
  useEffect(() => {
    const fetchInfo = async () => {
      const bankData = await getAllBanks();
      setBankNames(bankData);
      const firmData = await getAllFirms();
      setFirmNames(firmData);
      const siteData = await getAllSites();
      setSiteNames(siteData);
      // console.log(bankData);
      // console.log(firmData);
    };
    fetchInfo();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(e.target.files[0]);
    }
  };

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setUserFormData({ ...userFormData, [name]: value });
    // console.log(userFormData);
  };

  const handleBankInputChange = (e) => {
    const { name, value } = e.target;
    setErrors({ ...errors, [name]: "" });
    setBankFormData({ ...bankFormData, [name]: value });
  };

  const handleFirmInputChange = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, firm: "" });
    setFirmData(value);
  };

  const handleSiteInputChange = (e) => {
    const { value } = e.target;
    setErrors({ ...errors, site: "" });
    setSiteData(value);
  };

  const handleUserComboboxChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setUserFormData((prevuserFormData) => ({
      ...prevuserFormData,
      [name]: newValue,
    }));
  };

  const handleBankComboboxChange = (name, newValue) => {
    setErrors({ ...errors, [name]: "" });
    setBankFormData((prevBankFormData) => ({
      ...prevBankFormData,
      [name]: newValue,
    }));
  };

  const validateData = () => {
    const newErrors = {};
    if (caller === "team-add") {
      if (!userFormData.email) newErrors.email = t("E-Mail is required");
      if (!userFormData.username)
        newErrors.username = t("Username is required");
      if (!userFormData.password)
        newErrors.password = t("Password is required");
      if (!userFormData.site) newErrors.site = t("Site is required");
      if (!userFormData.firm) newErrors.firm = t("Firm is required");
      if (!userFormData.auth) newErrors.auth = t("Auth is required");
    } else if (caller === "team-edit") {
      if (!userFormData.site) newErrors.site = t("Site is required");
      if (!userFormData.firm) newErrors.firm = t("Firm is required");
    } else if (caller === "accounts-add") {
      if (!bankFormData.bank) newErrors.bank = t("Bank is required");
      if (!bankFormData.card_holder)
        newErrors.card_holder = t("Card Holder is required");
      if (!bankFormData.firm) newErrors.firm = t("Firm is required");
      if (!ibanValue || ibanError) newErrors.iban = t("IBAN is required");
      if (!bankFormData.limit_min)
        newErrors.limit_min = t("Min Limit are required");
      if (!bankFormData.limit_max)
        newErrors.limit_max = t("Max Limit are required");
    } else if (caller === "firms-add") {
      if (!firmData) newErrors.firm = t("Firm is required");
    } else if (caller === "sites-add") {
      if (!siteData) newErrors.site = t("Site is required");
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    closeModal();
    userFormData.password = userData.password;
    userFormData.auth = userData.auth;
    userFormData.email = userData.email;
    userFormData.last_ip = userData.last_ip;
    userFormData.last_login = userData.last_login;
    userFormData.process = "";
    userFormData.username = userData.username;
    // userFormData.uid = userID;
    // console.log(userData);
    const res = await updateUserData(userID, userFormData, profilePicture);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleUserAdd = async (e) => {
    const fieldErrors = validateData();

    if (Object.keys(fieldErrors).length === 0) {
      e.preventDefault();
      const res = await addUserData(userFormData, profilePicture);
      if (!res) onFeedback(t("Operation Successfull!"));
      else onFeedback(t("Operation Failed!"));
      clearFormData();
      closeModal();
      onClose();
    } else {
      setErrors(fieldErrors);
    }
  };

  const handleBankAdd = async (e) => {
    const fieldErrors = validateData();
    // console.log(bankFormData);
    if (Object.keys(fieldErrors).length === 0) {
      e.preventDefault();

      const res = await addBankAccount(bankFormData, ibanValue);
      if (!res) onFeedback(t("Operation Successfull!"));
      else onFeedback(t("Operation Failed!"));
      clearFormData();
      closeModal();
      onClose();
    } else {
      // console.log(fieldErrors);
      setErrors(fieldErrors);
    }
  };

  const handleCancel = (e) => {
    closeModal();
    onClose();
  };

  const handleDelete = async (e) => {
    const res = await deleteUserByUID(userID);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleBankDelete = async (e) => {
    const res = await deleteBankAccountByIBAN(extParam);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleFirmAdd = async (e) => {
    const fieldErrors = validateData();
    // console.log(firmData);
    if (Object.keys(fieldErrors).length === 0) {
      e.preventDefault();

      const res = await addFirm(firmData);
      if (!res) onFeedback(t("Operation Successfull!"));
      else onFeedback(t("Operation Failed!"));
      clearFormData();
      closeModal();
      onClose();
    } else {
      // console.log(fieldErrors);
      setErrors(fieldErrors);
    }
  };

  const handleFirmDelete = async (e) => {
    const res = await deleteFirm(extParam);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleSiteAdd = async (e) => {
    const fieldErrors = validateData();
    // console.log(firmData);
    if (Object.keys(fieldErrors).length === 0) {
      e.preventDefault();

      const res = await addSite(siteData);
      if (!res) onFeedback(t("Operation Successfull!"));
      else onFeedback(t("Operation Failed!"));
      clearFormData();
      closeModal();
      onClose();
    } else {
      // console.log(fieldErrors);
      setErrors(fieldErrors);
    }
  };

  const handleSiteDelete = async (e) => {
    const res = await deleteSite(extParam);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleInvestProcessConfirmation = async (e) => {
    // const curUser = getUserDataByUID(currentUser.uid);
    const res = await investProcessDone(extParam, currentUser);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleCancelReasonInputChange = (e) => {
    setCancelReason(e.target.value);
    clearFormData();
  };

  const handleInvestProcessCancellation = async (e) => {
    // const curUser = getUserDataByUID(currentUser.uid);
    const res = await investProcessCancelled(
      extParam,
      currentUser,
      cancelReason
    );
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleWithdrawalProcessConfirmation = async (e) => {
    // const curUser = getUserDataByUID(currentUser.uid);
    const res = await withdrawalProcessDone(extParam, currentUser);
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const handleWithdrawalProcessCancellation = async (e) => {
    // const curUser = getUserDataByUID(currentUser.uid);
    const res = await withdrawalProcessCancelled(
      extParam,
      currentUser,
      cancelReason
    );
    if (!res) onFeedback(t("Operation Successfull!"));
    else onFeedback(t("Operation Failed!"));
    clearFormData();
    onClose();
  };

  const clearFormData = () => {
    setUserFormData({
      auth: "",
      email: "",
      firm: [],
      last_ip: "",
      last_login: "",
      password: "",
      process: "",
      profilePictureURL: "",
      site: [],
      username: "",
    });
    setBankFormData({
      added: "",
      bank: "",
      card_holder: "",
      firm: "",
      iban: "",
      id: 0,
      limit_min: 0,
      limit_max: 0,
      logoURL: "",
      process: "",
      transaction_limit: 0,
    });
    setProfilePicture(null);
  };

  // const filterOptions = (options, { inputValue }) => {
  //   if (inputValue === t("All")) {
  //     // If "All" is selected, show only "All" and hide other options
  //     return options.filter((option) => option.label !== t("All"));
  //   }
  //   // Otherwise, show all options
  //   return options;
  // };

  const authOptions = [
    { label: "User", value: "User" },
    { label: "Guest", value: "Guest" },
    // Add more options as needed
  ];

  const modalStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    width: "30%",
    // height: "",
    transform: "translate(-50%, -50%)",
    padding: "10px",
    borderRadius: "8px",
    zIndex: "1000",
    maxWidth: "50%",
    maxHeight: "90%",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    animation: "dropTop .3s linear",
    ...(theme.palette.mode === "dark"
      ? {
          boxShadow: "1px 1px 4px 4px rgba(62, 67, 150, 1)",
          background: "rgba(20, 27, 45, 0.8)",
        }
      : {
          boxShadow: "1px 1px 4px 4px rgba(232, 93, 4, 1)",
          background: "rgba(255, 255, 255, 0.8)",
          //   background: "rgba(241, 185, 183, 0.8)",
        }),
  };

  const imageSize = {
    width: 256, // Adjust the width as needed
    height: 256, // Adjust the height as needed
  };

  const renderPhoto = () => {
    if (profilePicture) {
      return (
        <img
          src={URL.createObjectURL(profilePicture)}
          alt="User"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "cover", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (userData) {
      if (userData.profilePictureURL) {
        return (
          <img
            src={userData.profilePictureURL}
            alt="User"
            style={{
              width: imageSize.width,
              height: imageSize.height,
              objectFit: "scale-down", // Maintain aspect ratio and cover container
              paddingBottom: "10px",
            }}
          />
        );
        // biome-ignore lint/style/noUselessElse: <explanation>
      } else {
        return (
          <img
            src={emptyPhoto}
            alt="Empty"
            style={{
              width: imageSize.width,
              height: imageSize.height,
              objectFit: "cover", // Maintain aspect ratio and cover container
              paddingBottom: "10px",
            }}
          />
        );
      }
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      return (
        <img
          src={emptyPhoto}
          alt="Empty"
          style={{
            width: imageSize.width,
            height: imageSize.height,
            objectFit: "cover", // Maintain aspect ratio and cover container
            paddingBottom: "10px",
          }}
        />
      );
    }
  };

  if (caller === "team-edit") {
    return (
      <div className={modalClassName} style={modalStyle}>
        {userData && (
          <>
            <div className="modal-content">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 2,
                  position: "relative",
                  width: "100%",
                  // minWidth: 600,
                }}
              >
                <IconButton
                  aria-label="delete"
                  onClick={handleCancel}
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    margin: "0 0 10px 0",
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h4" sx={{ textAlign: "center" }}>
                  {t("Edit User Credentials")}
                </Typography>
              </Box>
              <Box
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 2,
                  position: "relative",
                  width: "100%",
                }}
              >
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {renderPhoto()}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      id="outlined-username"
                      label={t("Username")}
                      name="username"
                      defaultValue={userData.username}
                      onChange={handleUserInputChange}
                      error={!!errors.username}
                      helperText={
                        errors.username
                          ? errors.username
                          : t("You Can't Change Username")
                      }
                      color="secondary"
                      inputProps={{ style: { fontSize: 16 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="outlined-password"
                      label={t("Password")}
                      name="password"
                      type="password"
                      defaultValue=""
                      onChange={handleUserInputChange}
                      error={!!errors.password}
                      helperText={
                        errors.username
                          ? errors.username
                          : t("Enter New Password or Just Leave It Same")
                      }
                      color="secondary"
                      inputProps={{ style: { fontSize: 16 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={siteNames}
                      fullWidth
                      disablePortal
                      multiple
                      id="combobox-site"
                      name="site"
                      color="secondary"
                      value={userFormData.site || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handleUserComboboxChange("site", newValue)
                      }
                      defaultValue={[]}
                      // filterOptions={filterOptions}
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Site")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      options={firmNames}
                      fullWidth
                      disablePortal
                      multiple
                      id="combobox-firms"
                      name="firm"
                      color="secondary"
                      value={userFormData.firm || []}
                      filterSelectedOptions
                      // isOptionEqualToValue={(option, value) =>
                      //   option.label === value.value
                      // }
                      onChange={(event, newValue) =>
                        handleUserComboboxChange("firm", newValue)
                      }
                      defaultValue={[]}
                      // filterOptions={filterOptions}
                      getOptionLabel={(option) => option.toString()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Firms")}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {},
                              "&:hover fieldset": {
                                borderColor: "white",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: colors.greenAccent[500],
                              },
                            },
                            "& .MuiInputLabel-root": {
                              //   color: "purple",
                              "&.Mui-focused": {
                                color: colors.greenAccent[500],
                              },
                            },
                            "& .MuiOutlinedInput-input": {
                              //   color: "purple",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      component="label"
                      variant="outlined"
                      color="info"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                      style={{ height: "50px" }}
                    >
                      {t("Choose Profile Picture")}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type="submit"
                      variant="outlined"
                      color="secondary"
                      startIcon={<CheckIcon />}
                      onClick={handleSubmit}
                      style={{ marginRight: "10px", height: "50px" }}
                    >
                      {t("Confirm")}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      type="cancel"
                      variant="outlined"
                      color="error"
                      startIcon={<CancelIcon />}
                      onClick={handleCancel}
                      style={{ marginRight: "10px", height: "50px" }}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </>
        )}
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "team-delete") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("Are you sure to DELETE this user?")}
          </Typography>

          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleDelete}
                style={{ marginRight: "10px" }}
              >
                {t("Confirm")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "team-add") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <div className="modal-content">
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {t("Create New User")}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  {renderPhoto()}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-email"
                  label={t("E-mail")}
                  name="email"
                  defaultValue=""
                  onChange={handleUserInputChange}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email : t("Enter E-Mail")}
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-username"
                  label={t("Username")}
                  name="username"
                  defaultValue=""
                  onChange={handleUserInputChange}
                  error={!!errors.username}
                  helperText={
                    errors.username ? errors.username : t("Enter Username")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-password"
                  label={t("Password")}
                  name="password"
                  type="password"
                  defaultValue=""
                  onChange={handleUserInputChange}
                  error={!!errors.password}
                  helperText={
                    errors.email ? errors.password : t("Enter Password")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={siteNames}
                  fullWidth
                  disablePortal
                  multiple
                  id="combobox-site"
                  name="site"
                  color="secondary"
                  value={userFormData.site || []}
                  filterSelectedOptions
                  // isOptionEqualToValue={(option, value) =>
                  //   option.label === value.value
                  // }
                  onChange={(event, newValue) =>
                    handleUserComboboxChange("site", newValue)
                  }
                  defaultValue={[]}
                  // filterOptions={filterOptions}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Site")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {},
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.greenAccent[500],
                          },
                        },
                        "& .MuiInputLabel-root": {
                          //   color: "purple",
                          "&.Mui-focused": {
                            color: colors.greenAccent[500],
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //   color: "purple",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={firmNames}
                  fullWidth
                  disablePortal
                  multiple
                  id="combobox-firms"
                  name="firm"
                  color="secondary"
                  value={userFormData.firm || []}
                  filterSelectedOptions
                  // isOptionEqualToValue={(option, value) =>
                  //   option.label === value.value
                  // }
                  onChange={(event, newValue) =>
                    handleUserComboboxChange("firm", newValue)
                  }
                  defaultValue={[]}
                  // filterOptions={filterOptions}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Firms")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {},
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.greenAccent[500],
                          },
                        },
                        "& .MuiInputLabel-root": {
                          //   color: "purple",
                          "&.Mui-focused": {
                            color: colors.greenAccent[500],
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //   color: "purple",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  fullWidth
                  disablePortal
                  id="combobox-role"
                  name="auth"
                  options={authOptions}
                  color="secondary"
                  isOptionEqualToValue={(option, value) =>
                    option.label === value.value
                  }
                  onChange={(event, newValue) =>
                    handleUserComboboxChange(
                      "auth",
                      newValue ? newValue.value : ""
                    )
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Authority Level")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {},
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.greenAccent[500],
                          },
                        },
                        "& .MuiInputLabel-root": {
                          //   color: "purple",
                          "&.Mui-focused": {
                            color: colors.greenAccent[500],
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //   color: "purple",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  component="label"
                  variant="outlined"
                  color="info"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  style={{ height: "50px" }}
                >
                  {t("Choose Profile Picture")}
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CheckIcon />}
                  onClick={handleUserAdd}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Confirm")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="cancel"
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "accounts-add") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <div className="modal-content">
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {t("Add New Account")}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={bankNames}
                  fullWidth
                  disablePortal
                  id="combobox-bank"
                  name="bank"
                  color="secondary"
                  value={bankFormData.bank || null}
                  onChange={(event, newValue) =>
                    handleBankComboboxChange("bank", newValue)
                  }
                  defaultValue={""}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Bank")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {},
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.greenAccent[500],
                          },
                        },
                        "& .MuiInputLabel-root": {
                          //   color: "purple",
                          "&.Mui-focused": {
                            color: colors.greenAccent[500],
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //   color: "purple",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-cardholder"
                  label={t("Card Holder")}
                  name="card_holder"
                  defaultValue=""
                  onChange={handleBankInputChange}
                  error={!!errors.card_holder}
                  helperText={
                    errors.card_holder
                      ? errors.card_holder
                      : t("Enter Card Holder")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <IBANInput />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-trlimit"
                  label={t("Transaction Limit")}
                  name="transaction_limit"
                  defaultValue=""
                  onChange={handleBankInputChange}
                  error={!!errors.transaction_limit}
                  helperText={
                    errors.transaction_limit
                      ? errors.transaction_limit
                      : t("Enter Transaction Limit")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-limitmin"
                  label={t("Minimum Limit")}
                  name="limit_min"
                  defaultValue=""
                  onChange={handleBankInputChange}
                  error={!!errors.limit_min}
                  helperText={
                    errors.limit_min
                      ? errors.limit_min
                      : t("Enter Minimum Limit")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-limitmax"
                  label={t("Maximum Limit")}
                  name="limit_max"
                  defaultValue=""
                  onChange={handleBankInputChange}
                  error={!!errors.limit_max}
                  helperText={
                    errors.limit_max
                      ? errors.limit_max
                      : t("Enter Maximum Limit")
                  }
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  options={firmNames}
                  fullWidth
                  disablePortal
                  multiple
                  id="combobox-firm"
                  name="firm"
                  color="secondary"
                  value={bankFormData.firm || []}
                  filterSelectedOptions
                  onChange={(event, newValue) =>
                    handleBankComboboxChange("firm", newValue)
                  }
                  defaultValue={[]}
                  getOptionLabel={(option) => option.toString()}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("Firms")}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {},
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: colors.greenAccent[500],
                          },
                        },
                        "& .MuiInputLabel-root": {
                          //   color: "purple",
                          "&.Mui-focused": {
                            color: colors.greenAccent[500],
                          },
                        },
                        "& .MuiOutlinedInput-input": {
                          //   color: "purple",
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CheckIcon />}
                  onClick={handleBankAdd}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Confirm")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="cancel"
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "account-delete") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("Are you sure to DELETE this account?")}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleBankDelete}
                style={{ marginRight: "10px" }}
              >
                {t("Confirm")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "firms-add") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <div className="modal-content">
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {t("Add New Firm")}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-firm"
                  label={t("Firm")}
                  name="firm"
                  defaultValue=""
                  onChange={handleFirmInputChange}
                  error={!!errors.firm}
                  helperText={errors.firm ? errors.firm : t("Enter Firm")}
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CheckIcon />}
                  onClick={handleFirmAdd}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Confirm")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="cancel"
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "firms-delete") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("Are you sure to DELETE this firm?")}
          </Typography>

          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleFirmDelete}
                style={{ marginRight: "10px" }}
              >
                {t("Confirm")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "sites-add") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <div className="modal-content">
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              {t("Add New Site")}
            </Typography>
            <IconButton
              aria-label="delete"
              onClick={handleCancel}
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              position: "relative",
              width: "100%",
            }}
          >
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="outlined-site"
                  label={t("Site")}
                  name="site"
                  defaultValue=""
                  onChange={handleSiteInputChange}
                  error={!!errors.site}
                  helperText={errors.site ? errors.site : t("Enter Site")}
                  color="secondary"
                  inputProps={{ style: { fontSize: 16 } }}
                />
              </Grid>

              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="outlined"
                  color="secondary"
                  startIcon={<CheckIcon />}
                  onClick={handleSiteAdd}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Confirm")}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="cancel"
                  variant="outlined"
                  color="error"
                  startIcon={<CancelIcon />}
                  onClick={handleCancel}
                  style={{ marginRight: "10px", height: "50px" }}
                >
                  {t("Cancel")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "sites-delete") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t("Are you sure to DELETE this site?")}
          </Typography>

          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleSiteDelete}
                style={{ marginRight: "10px" }}
              >
                {t("Confirm")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
                style={{ marginRight: "10px" }}
              >
                {t("Cancel")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "invest-confirm") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t(
              "The transaction will be confirmed and moved to the pool. Are you sure?"
            )}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleInvestProcessConfirmation}
              >
                {t("Confirm the process")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel the process")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "invest-cancel") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t(
              "The transaction will be cancelled and moved to the pool. Are you sure?"
            )}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-reason"
                label={t("Reason of Cancellation")}
                variant="outlined"
                color="secondary"
                multiline
                onChange={handleCancelReasonInputChange}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleInvestProcessCancellation}
              >
                {t("Cancel the process")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel1")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "withd-confirm") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t(
              "The transaction will be confirmed and moved to the pool. Are you sure?"
            )}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleWithdrawalProcessConfirmation}
              >
                {t("Confirm the process")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel the process")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else if (caller === "withd-cancel") {
    return (
      <div className={modalClassName} style={modalStyle}>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            {t(
              "The transaction will be cancelled and moved to the pool. Are you sure?"
            )}
          </Typography>
          <IconButton
            aria-label="delete"
            onClick={handleCancel}
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: 2,
            position: "relative",
            width: "100%",
          }}
        >
          <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-reason"
                label={t("Reason of Cancellation")}
                variant="outlined"
                color="secondary"
                multiline
                onChange={handleCancelReasonInputChange}
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="secondary"
                startIcon={<CheckIcon />}
                onClick={handleWithdrawalProcessCancellation}
              >
                {t("Cancel the process")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="cancel"
                variant="outlined"
                color="error"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel1")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
};

export default Modal;
