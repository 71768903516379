import React from "react";
import axios from "axios";
import { Button } from "@mui/material";
import * as Sentry from "@sentry/react";

const PostDataComponent = ({ dataToPost, type, buttonText, apiID, apiKey }) => {
  const postInvest = async () => {
    try {
      const response = await axios.post(
        // "http://localhost:5000/reqinv",
        // "http://httpbin.org/post",
        // "https://us-central1-react-firebase-test-9b7d3.cloudfunctions.net/api/reqinv",
        "https://us-central1-ultrahavale-app.cloudfunctions.net/api/reqinv",
        dataToPost,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-id": apiID,
            "x-api-key": apiKey,
          },
        }
      );

      Sentry.captureMessage(`Response: ${JSON.stringify(response.data)}`);
    } catch (error) {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          message: "Error occurred during axios.post request",
          response: error.response ? error.response.data : null, // Include response data if available
        },
      });
    }
  };

  const postWithdrawal = async () => {
    Sentry.captureMessage(dataToPost);
    try {
      const response = await axios.post(
        // "http://localhost:5000/reqwit",
        // "http://httpbin.org/post",
        // "https://us-central1-react-firebase-test-9b7d3.cloudfunctions.net/api/reqwit",
        "https://us-central1-ultrahavale-app.cloudfunctions.net/api/reqwit",
        dataToPost,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-id": apiID,
            "x-api-key": apiKey,
          },
        }
      );

      Sentry.captureMessage(`Response: ${JSON.stringify(response.data)}`);
    } catch (error) {
      Sentry.captureException(error, {
        level: "error",
        extra: {
          message: "Error occurred during axios.post request",
          response: error.response ? error.response.data : null, // Include response data if available
        },
      });
    }
  };

  return (
    <div>
      <Button
        sx={{
          color: "#FFFFFF",
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          margin: "0 10px 0 0",
          backgroundColor: "#13AD23",
        }}
        onClick={type === "Invest" ? postInvest : postWithdrawal}
      >
        {/* <PersonAddIcon sx={{ mr: "10px" }} /> */}
        {buttonText}
      </Button>
    </div>
  );
};

export default PostDataComponent;
