// src/screens/Cancelled.jsx

import React from "react";
import { useAuth } from "../components/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import CustomLinearProgress, {
  CustomToolbar,
  //   OvalButton,
  BankChip,
} from "../components/Common";
import { useData } from "../components/DataProvider";
// import Modal from "./Modal";

const Cancelled = () => {
  const { currentUser } = useAuth();
  const { cancelled, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  //   const [entryID, setEntryID] = useState("");
  //   const [caller, setCaller] = useState(null);
  //   const [isModalOpen, setIsModalOpen] = useState(false);

  //   const closeModal = () => {
  //     setIsModalOpen(false);
  //   };

  //   const handleEdit = (id, process) => {
  //     console.log(`Editing item with ID: ${id}`);
  //     setCaller(process);
  //     setEntryID(id);
  //     setIsModalOpen(true);
  //   };

  // Function to format each string into groups of four digits
  const formatText = (text) => {
    return isFormatted(text) ? text : text.match(/.{1,4}/g).join(" ");
  };

  const isFormatted = (text) => {
    // Check if the text is already formatted (assuming it's split by spaces)
    return /^[a-zA-Z]{2}\w{2} \w{4} \w{4} \w{4} \w{4} \w{4} \w{2}$/.test(
      text.trim()
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "user_id",
      headerName: t("User ID"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "username",
      headerName: t("Username"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "id_number",
      headerName: t("ID Number"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 110,
    },
    {
      field: "real_name",
      headerName: t("Full Name"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "transaction_type",
      headerName: t("Type"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (params) => (
        <BankChip
          {...params}
          color={params.row.value === "Withdrawal" ? "error" : "success"}
        />
      ),
    },
    {
      field: "value",
      headerName: t("Amount"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "bank_info",
      headerName: t("Bank Account"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 450,
      renderCell: (params) => (
        <div>
          {Array.isArray(params.value) ? (
            params.value.map((item, index) => {
              if (item.length >= 26)
                return isFormatted(item) ? (
                  <BankChip value={item} color="info" />
                ) : (
                  <BankChip value={formatText(item)} color="info" />
                );
              // biome-ignore lint/style/noUselessElse: <explanation>
              else return <BankChip value={item} color="warning" />;
            })
          ) : (
            <BankChip value={params.value} />
          )}
        </div>
      ),
    },
    {
      field: "cancellation_reason",
      headerName: t("Cancellation Reason"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "cancelled_by",
      headerName: t("Cancelled By"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "timestamp_order",
      headerName: t("Order Date-Time"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "timestamp_process",
      headerName: t("Process Date-Time"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "site",
      headerName: t("Website"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "notes",
      headerName: t("Notes"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "order_no",
      headerName: t("Order Number"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "process_status",
      headerName: t("Status"),
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => <BankChip {...params} color="success" />,
    },
  ];

  if (currentUser.auth !== "Guest") {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="25px"
        >
          <Header
            title={t("Cancelled")}
            subtitle={t("All cancelled processes")}
          />
        </Box>
        {/* {isModalOpen && (
          <Modal
            onClose={closeModal}
            caller={caller}
            extParam={entryID}
            currentUser={currentUser}
          />
        )} */}
        <Box
          m="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          //height="100vh" /* Full height of the viewport */
          min-height="0" /* Ensure it can shrink */
          width="99%"
          sx={{
            "& .MuiDataGrid-root": {
              fontSize: "16px",
              ...(theme.palette.mode === "dark"
                ? {
                    border: "1px solid rgba(62, 67, 150, 1)",
                  }
                : { border: "1px solid rgba(232, 93, 4, 1)" }),
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              fontSize: "16px",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "1.2rem",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              ...(theme.palette.mode === "dark"
                ? {
                    backgroundColor: colors.blueAccent[700],
                  }
                : { backgroundColor: colors.orangeAccent[800] }),
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-cell:hover": {
              ...(theme.palette.mode === "dark"
                ? {
                    color: colors.blueAccent[500],
                  }
                : { color: colors.orangeAccent[500] }),
            },
          }}
        >
          <DataGrid
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
              notes: false,
              process_status: false,
              order_no: false,
            }}
            checkboxSelection={false}
            rows={cancelled}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: CustomLinearProgress,
            }}
            loading={loading}
            pageSize={5}
            autoHeight
            autoWidth
            // autoPageSize
            disableSelectionOnClick
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="25px"
        >
          <Header
            title={t("403")}
            subtitle={t("You don't have permission to see this page")}
          />
        </Box>
      </Box>
    );
  }
};

export default Cancelled;
