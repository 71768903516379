// src/screens/SignUp.jsx

import React, { useState } from "react";
import { firestore, storage, auth } from "../firebaseConfig";
import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword } from "firebase/auth";

const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("User"); // Default to 'User'
  const [password, setPassword] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(""); // State for success message

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    try {
      // Check if the email already exists in Firestore
      const usersRef = collection(firestore, "users");
      const q = query(usersRef, where("email", "==", email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        setError("User with this email already exists.");
        return;
      }

      // Create a new user in Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      let profilePictureURL = "";

      // Upload profile picture if it exists
      if (profilePicture) {
        const cleanedName = name.replace(/\s+/g, "_").toLowerCase(); // Convert spaces to underscores and make lowercase
        const extension = profilePicture.name.split(".").pop(); // Get the file extension
        const profilePictureRef = ref(
          storage,
          `profilePictures/${cleanedName}.${extension}`
        );
        await uploadBytes(profilePictureRef, profilePicture);
        profilePictureURL = await getDownloadURL(profilePictureRef);
      }

      // Add new user document to Firestore
      const docRef = await addDoc(usersRef, {
        uid: user.uid, // Store the user's UID from Firebase Authentication
        username: name,
        email: email,
        auth: role,
        profilePictureURL: profilePictureURL,
        password: password,
        process:"",
        site:"",
        last_login:"",
        last_ip: "",
        firm:""
      });
      console.log("Document written with ID: ", docRef.id);
      setName(""); // Clear the input fields
      setEmail("");
      setRole("User");
      setPassword("");
      setProfilePicture(null);
      setSuccess("User successfully added."); // Set success message
    } catch (e) {
      console.error("Error adding document: ", e);
      setError("Error adding user. Please try again.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setProfilePicture(e.target.files[0]);
    }
  };

  return (
    <div>
      <h1>Add User</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
        />
        <select value={role} onChange={(e) => setRole(e.target.value)}>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
          <option value="Guest">Guest</option>
        </select>
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="file" onChange={handleFileChange} />
        <button type="submit">Add User</button>
      </form>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}{" "}
      {/* Render success message */}
    </div>
  );
};

export default SignUp;
