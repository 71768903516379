// src/screens/Dashboard.jsx

import React, { useEffect, useState } from "react";
import { useAuth } from "../components/AuthContext";
import { firestore } from "../firebaseConfig";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import { PieChart } from "@mui/x-charts/PieChart";
import { BarChart } from "@mui/x-charts/BarChart";
import { SparkLineChart } from "@mui/x-charts/SparkLineChart";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { LineChart } from "@mui/x-charts/LineChart";
import Stack from "@mui/material/Stack";

const pieParams = { height: 200, margin: { right: 5 } };
const palette = ["red", "blue", "green"];

const data = [
  { name: "Group A", value: 400 },
  { name: "Group B", value: 300 },
  { name: "Group C", value: 300 },
  { name: "Group D", value: 200 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const Dashboard = () => {
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  if (currentUser.auth !== "Guest") {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="30px"
        >
          <Header
            title={t("DASHBOARD")}
            subtitle={t("Welcome to your dashboard")}
          />
        </Box>

        <Stack direction="row" width="100%" textAlign="center" spacing={2}>
          <Box
            flexGrow={1}
            alignContent={"center"}
            alignItems="center"
            justifyContent="space-between"
            margin="50px 0 50px 0"
            width="50%"
            height="500px"
          >
            <Typography variant="r20" color={colors.greenAccent[400]}>
              {"Testing Pie Chart"}
            </Typography>

            <PieChart
              sx={{ margin: "20px 0 0 0" }}
              series={[
                {
                  data: [
                    { value: 10, color: COLORS[0] },
                    { value: 15, color: COLORS[1] },
                    { value: 20, color: COLORS[2] },
                  ],
                  arcLabel: (params) => params.label ?? "",
                  highlightScope: { faded: "global", highlighted: "item" },
                  innerRadius: 120,
                  outerRadius: 250,
                  paddingAngle: 5,
                  cornerRadius: 5,
                  startAngle: -180,
                  endAngle: 180,
                  // cx: "50%",
                  // cy: "50%",
                },
              ]}
              // width={500}
              // height={500}
              // {...pieParams}
            />
          </Box>

          <BarChart
            series={[
              { data: [3, 4, 1, 6, 5], stack: "A", label: "Series A1" },
              { data: [4, 3, 1, 5, 8], stack: "A", label: "Series A2" },
              { data: [4, 2, 5, 4, 1], stack: "B", label: "Series B1" },
              { data: [2, 8, 1, 3, 1], stack: "B", label: "Series B2" },
              { data: [10, 6, 5, 8, 9], label: "Series C1" },
            ]}
            borderRadius={8}
            height={500}
            // xAxis={[{ data: ["Q1", "Q2", "Q3", "Q4"], scaleType: "band" }]}
            // margin={{ top: 10, bottom: 30, left: 40, right: 10 }}
          />
        </Stack>
        <Stack direction="row" sx={{ width: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <SparkLineChart
              data={[3, -10, -2, 5, 7, -2, 4, 6]}
              showHighlight
              showTooltip
              height={400}
              area
              colors={["red"]}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <SparkLineChart
              data={[3, -10, -2, 5, 7, -2, 4, 6]}
              showHighlight
              showTooltip
              height={400}
              curve="natural"
              area
            />
          </Box>
        </Stack>
        <LineChart
          xAxis={[{ data: [1, 2, 3, 5, 8, 10, 12, 15, 16] }]}
          series={[
            {
              data: [2, 5.5, 2, 8.5, 1.5, 5],
              valueFormatter: (value) =>
                value == null ? "NaN" : value.toString(),
            },
            {
              data: [null, null, null, null, 5.5, 2, 8.5, 1.5, 5],
            },
            {
              data: [7, 8, 5, 4, null, null, 2, 5.5, 1],
              valueFormatter: (value) =>
                value == null ? "?" : value.toString(),
            },
          ]}
          height={200}
          margin={{ top: 10, bottom: 20 }}
        />
        {/* <Gauge
          value={75}
          startAngle={-110}
          endAngle={110}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40,
              transform: "translate(0px, 0px)",
            },
          }}
          // text={({ value, valueMax }) => `${value} / ${valueMax}`}
        /> */}
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    // Render for Guests
    return (
      <Box>
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="30px"
        >
          <Header title={t("DASHBOARD")} subtitle={t("Welcome Guest!")} />
        </Box>
      </Box>
    );
  }
};

export default Dashboard;
