// src/components/AuthContext.jsx

import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore } from "../firebaseConfig";
import { onAuthStateChanged, getIdToken, signOut } from "firebase/auth";
import { collection, getDocs, query, where } from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch user data from Firestore
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = collection(firestore, "users");
          const q = query(docRef, where("uid", "==", user.uid));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const documents = querySnapshot.docs.map((doc) => doc.data());
            setCurrentUser(documents[0]);
            const token = await getIdToken(user);
            setToken(token);
          } else {
            setCurrentUser(null);
            setToken(null);
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    });

    return unsubscribe;
  }, []);

  const handleSignOut = async () => {
    await signOut(auth);
    setCurrentUser(null);
    setToken(null);
  };

  return (
    <AuthContext.Provider
      value={{ currentUser, token, loading, signOut: handleSignOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
