// src/components/DataProvider.jsx

import React, { createContext, useContext, useEffect, useState } from "react";
import { firestore } from "../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { format } from "date-fns";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [allInvestments, setAllInvestments] = useState([]);
  const [allWithdrawals, setAllWithdrawals] = useState([]);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [totalActiveAccount, setTotalActiveAccount] = useState(1);
  const [cancelled, setCancelled] = useState([]);
  const [waitingInvestments, setWaitingInvestments] = useState([]);
  const [waitingWithdrawals, setWaitingWithdrawals] = useState([]);
  const [firms, setFirms] = useState([]);
  const [sites, setSites] = useState([]);
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Setup Firestore listeners
    const unsubscribeAllInvestments = onSnapshot(
      collection(firestore, "investments_all"),
      (snapshot) => {
        const investData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp_order: format(
            doc.data().datetime.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          timestamp_process: format(
            doc.data().process_date.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          //arrayField: doc.data().bank_info.join(" / "),
        }));
        console.log(investData);
        setAllInvestments(investData);
      }
    );

    const unsubscribeAllWithdrawals = onSnapshot(
      collection(firestore, "withdrawals_all"),
      (snapshot) => {
        const withdrawalData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp_order: format(
            doc.data().datetime.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          timestamp_process: format(
            doc.data().process_date.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          //arrayField: doc.data().bank_info.join(" / "),
        }));
        setAllWithdrawals(withdrawalData);
      }
    );

    const unsubscribeBankAccounts = onSnapshot(
      collection(firestore, "bank_accounts"),
      (snapshot) => {
        try {
          const bankData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            arrayField: doc.data().limits.join(" / "),
          }));
          // console.log(updatedData);
          setBankAccounts(bankData);
          // Count numeric field
          const count = bankData.reduce(
            (acc, row) => acc + (row.process === "Active" ? 1 : 0),
            0
          );
          setTotalActiveAccount(count);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    );

    const unsubscribeCancelled = onSnapshot(
      collection(firestore, "cancelled"),
      (snapshot) => {
        const cancelledData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp_order: format(
            doc.data().datetime.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          timestamp_process: format(
            doc.data().process_date.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          //arrayField: doc.data().bank_info.join(" / "),
        }));
        setCancelled(cancelledData);
      }
    );

    const unsubscribeWaitingInvestments = onSnapshot(
      collection(firestore, "invests_w"),
      (snapshot) => {
        const investData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp: format(
            doc.data().datetime.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          //arrayField: doc.data().bank_info.join(" / "),
        }));
        setWaitingInvestments(investData);
      }
    );

    const unsubscribeWaitingWithdrawals = onSnapshot(
      collection(firestore, "withdrawals_w"),
      (snapshot) => {
        const withdrawalData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp: format(
            doc.data().datetime.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
          //arrayField: doc.data().bank_info.join(" / "),
        }));
        setWaitingWithdrawals(withdrawalData);
      }
    );

    const unsubscribeFirms = onSnapshot(
      collection(firestore, "firms"),
      (snapshot) => {
        try {
          const firmData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // arrayField: doc.data().limits.join(" / "),
          }));

          // console.log(updatedData);
          setFirms(firmData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    );

    const unsubscribeSites = onSnapshot(
      collection(firestore, "sites"),
      (snapshot) => {
        try {
          const siteData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            // arrayField: doc.data().limits.join(" / "),
          }));

          // console.log(updatedData);
          setSites(siteData);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
        }
      }
    );

    const unsubscribeUsers = onSnapshot(
      collection(firestore, "users"),
      (snapshot) => {
        const userData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          // Convert Firestore timestamp to a readable date format
          timestamp: format(
            doc.data().last_login.toDate(),
            "dd/MM/yyyy HH:mm:ss"
          ),
        }));
        setTeam(userData);
      }
    );

    setLoading(false);

    // Cleanup on unmount
    return () => {
      unsubscribeAllInvestments();
      unsubscribeAllWithdrawals();
      unsubscribeBankAccounts();
      unsubscribeCancelled();
      unsubscribeWaitingInvestments();
      unsubscribeWaitingWithdrawals();
      unsubscribeFirms();
      unsubscribeSites();
      unsubscribeUsers();
    };
  }, []);

  return (
    <DataContext.Provider
      value={{
        allInvestments,
        allWithdrawals,
        bankAccounts,
        totalActiveAccount,
        cancelled,
        waitingInvestments,
        waitingWithdrawals,
        firms,
        sites,
        team,
        loading,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
