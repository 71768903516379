// src/screens/Test.jsx

import React from "react";
import { useAuth } from "../components/AuthContext";
import { Box } from "@mui/material";
import Header from "../components/Header";
import PostDataComponent from "../components/PostDataComponent";

// Currently we are using hashed one
const apiID = "8de1d77d-d06a-41e9-b29f-3395ef9da952";
// const apiKey = "d5031479-fda1-40eb-93b2-89fb3a1e1124";
const hashedApiKey =
  "$2a$10$VV3s9Lv3VIdvNDe0ydyc9uBKFKN024ZPm5tMwYXzH8PanLNKmBgsW";

const investData = {
  bank_info: [
    "Türkiye Finans",
    // "TR111111111111111111111111",
    // "TR410020600343050735160001",
    "TR41 0020 6003 4305 0735 1600 01",
  ],
  id_number: "35303268482",
  notes: "john.doe@example.com",
  order_no: "XC524163",
  real_name: "Alican Umut",
  site: "GG Bet",
  user_id: 156458687,
  username: "GG",
  value: 128000,
};

const withdrawalData = {
  bank_info: [
    "Türkiye Finans",
    // "TR111111111111111111111111",
    "TR410020600343050735160001",
    // "TR41 0020 6003 4305 0735 1600 01",
  ],
  id_number: "35303268482",
  // notes: "john.doe@example.com",
  // order_no: "XC524163",
  real_name: "Alican Umut",
  site: "GG Bet",
  user_id: 156458687,
  username: "GG",
  value: 128000,
};

const Test = () => {
  const { currentUser } = useAuth();

  if (currentUser.auth !== "Guest") {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="30px"
        >
          <Header
            title={"Test Sayfası"}
            subtitle={
              "Hoşgeldin! Butonlar ile yatırım ya da çekim talep edebilirsin."
            }
          />

          <PostDataComponent
            dataToPost={investData}
            type="Invest"
            buttonText="Yatırım Talebi İste"
            apiID={apiID}
            apiKey={hashedApiKey}
            // apiKey={apiKey}
          />

          <PostDataComponent
            dataToPost={withdrawalData}
            type="Withdrawal"
            buttonText="Çekim Talebi İste"
            apiID={apiID}
            apiKey={hashedApiKey}
            // apiKey={apiKey}
          />
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    // Render for Guests
    return (
      <Box>
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="30px"
        >
          <Header
            title={"403"}
            subtitle={"You don't have permission to see this page"}
          />
        </Box>
      </Box>
    );
  }
};

export default Test;
