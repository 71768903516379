// src/components/Warbar.jsx

import { useState, useContext } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme, Grid } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import { useTranslation } from "react-i18next";
import { useAuth } from "./AuthContext";
import { useNavigate } from "react-router-dom";
// Icons
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatListNumberedRtlIcon from "@mui/icons-material/FormatListNumberedRtl";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import LogoutOutlinedIcon from "@mui/icons-material/Logout";
// import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import BusinessIcon from "@mui/icons-material/Business";
// Logo
// import UltraHavaleIcon from "../assets/UltraHavale_32.png";
import UltraHavaleLogo from "../assets/UltraHavale.png";

const Warbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const colorMode = useContext(ColorModeContext);
  const { i18n, t } = useTranslation();
  const { currentUser, signOut } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const changeLanguage = () => {
    const newLanguage = i18n.language === "en" ? "tr" : "en";
    i18n.changeLanguage(newLanguage);
  };

  const handleButtonClick = (path) => {
    navigate(path);
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        minHeight: "400px",
      }}
    >
      <Sidebar
        collapsed={isCollapsed}
        //image="https://i.pinimg.com/736x/8e/6c/06/8e6c064f57f94838263d7ba9ad80f353.jpg"
        rootStyles={{
          background: "transparent !important",
          ...(theme.palette.mode === "dark"
            ? {
                background:
                  "linear-gradient(180deg, rgba(0,76,195,1) 0%, rgba(0,3,149,1) 50%, rgba(13,0,80,1) 100%) !important",
              }
            : {
                background:
                  "linear-gradient(180deg, rgba(220, 47, 2, 1) 0%, rgba(157, 2, 8, 1) 50%, rgba(55, 6, 23, 1) 100%) !important",
                // "linear-gradient(180deg, rgba(195, 0, 92, 1) 0%, rgba(163, 0, 58, 1) 50%, rgba(125, 0, 0, 1) 100%) !important",
              }),
        }}
      >
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}

          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.primary[600],
            }}
          >
            {/* Menu Icon */}
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography
                  variant="r20"
                  color={colors.primary[600]}
                  noWrap
                  component="div"
                  className="gradient-text-orange"
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                    },
                  }}
                >
                  ULTRA HAVALE
                </Typography>
                <IconButton
                  onClick={() => setIsCollapsed(!isCollapsed)}
                  style={{ color: colors.primary[600] }}
                >
                  <MenuOutlinedIcon style={{ color: "#c93103" }} />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {/* Logo */}
          {!isCollapsed && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              alignItems="center"
              margin="0 0 25px 0"
            >
              <img
                src={UltraHavaleLogo}
                alt="UltraHavale"
                style={{
                  width: 128,
                  height: 128,
                  objectFit: "cover", // Maintain aspect ratio and cover container
                  paddingBottom: "0px",
                }}
              />
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "0%"}>
            {currentUser.auth !== "Guest" && (
              <>
                {/* Dashboard Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/dashboard")}
                  style={{
                    margin: "10px 0 20px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <HomeOutlinedIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Dashboard")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Management Label */}
                <Typography
                  variant={isCollapsed ? "r16" : "r20"}
                  style={{
                    margin: "0 0 0 10px",
                    color: colors.orangeAccent[500],
                  }}
                >
                  {t("Manage")}
                </Typography>

                {/* Waiting Investments Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/investments")}
                  style={{
                    margin: "10px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <HourglassTopIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Waiting Investments")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Waiting Withdrawals Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/withdrawals")}
                  style={{
                    margin: "0px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <HourglassBottomIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Waiting Withdrawals")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* All Investments Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/all_investments")}
                  style={{
                    margin: "0px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <FormatListNumberedIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("All Investments")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* All Withdrawals Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/all_withdrawals")}
                  style={{
                    margin: "0px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <FormatListNumberedRtlIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("All Withdrawals")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Reports Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/reports")}
                  style={{
                    margin: "0px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <SummarizeIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Reports")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Cancelled Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/cancelled")}
                  style={{
                    margin: "0px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <CancelPresentationIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Cancelled")}
                    </Typography>
                  </Box>
                </MenuItem>
              </>
            )}

            {/* Site Report Button */}
            <MenuItem
              onClick={() => handleButtonClick("/site_report")}
              style={{
                margin: "0px 0 10px 0",
                color: colors.orangeAccent[500],
              }}
            >
              <Box display="flex" alignItems="center" ml="10px">
                <AssessmentIcon />
                <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                  {t("Site Report")}
                </Typography>
              </Box>
            </MenuItem>

            {currentUser.auth !== "Guest" && (
              <>
                {/* Settings Label */}
                <Typography
                  variant={isCollapsed ? "r16" : "r20"}
                  style={{
                    marginLeft: isCollapsed ? "15px" : "10px",
                    color: colors.orangeAccent[500],
                  }}
                >
                  {t("Settings")}
                </Typography>

                {/* Team Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/team")}
                  style={{
                    margin: "10px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <PeopleOutlinedIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Team")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Accounts Button */}
                <MenuItem
                  onClick={() => handleButtonClick("/bankaccounts")}
                  style={{
                    margin: "10px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <CreditCardIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Accounts")}
                    </Typography>
                  </Box>
                </MenuItem>

                {/* Sites Button */}
                {/* <MenuItem
                  onClick={() => handleButtonClick("/sites")}
                  style={{
                    margin: "10px 0 0px 0",
                    color: colors.orangeAccent[500],
                  }}
                >
                  <Box display="flex" alignItems="center" ml="10px">
                    <LaptopMacIcon />
                    <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                      {t("Sites")}
                    </Typography>
                  </Box>
                </MenuItem> */}
              </>
            )}

            {/* Firms & Sites Button */}
            {currentUser.auth === "GODLIKE" && (
              <MenuItem
                onClick={() => handleButtonClick("/firmsandsites")}
                style={{
                  margin: "10px 0 0px 0",
                  color: colors.orangeAccent[500],
                }}
              >
                <Box display="flex" alignItems="center" ml="10px">
                  <BusinessIcon />
                  <Typography variant="r16" style={{ margin: "0 0 0 20px" }}>
                    {t("Firms & Sites")}
                  </Typography>
                </Box>
              </MenuItem>
            )}

            {/* User Info */}
            {!isCollapsed && (
              <Box mb="25px">
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    alt="profile-user"
                    width="100px"
                    height="100px"
                    src={currentUser.profilePictureURL}
                    style={{
                      cursor: "pointer",
                      borderRadius: "50%",
                      margin: "30px 0 0 0",
                    }}
                  />
                </Box>
                <Box textAlign="center">
                  <Typography
                    variant="h3"
                    color={colors.orangeAccent[600]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0" }}
                  >
                    {currentUser.username}
                  </Typography>
                  <Typography variant="h4" color={colors.greenAccent[700]}>
                    {currentUser.auth}
                  </Typography>
                </Box>
              </Box>
            )}

            {/* Controls Label */}
            {!isCollapsed && (
              <Box textAlign="center">
                <Typography
                  variant={isCollapsed ? "r16" : "r20"}
                  style={{
                    color: colors.orangeAccent[500],
                  }}
                >
                  {t("Controls")}
                </Typography>
              </Box>
            )}

            {!isCollapsed && (
              <Grid container margin="0 0 0 15px">
                <Grid item xs={4}>
                  <IconButton
                    onClick={colorMode.toggleColorMode}
                    style={{
                      color: colors.orangeAccent[500],
                      textDecoration: "none",
                      // color: "inherit",
                    }}
                  >
                    {theme.palette.mode === "dark" ? (
                      <DarkModeOutlinedIcon />
                    ) : (
                      <LightModeOutlinedIcon />
                    )}
                  </IconButton>
                </Grid>

                <Grid item xs={4}>
                  <IconButton
                    onClick={changeLanguage}
                    style={{
                      color: colors.orangeAccent[500],
                      textDecoration: "none",
                      // color: "inherit",
                    }}
                  >
                    <LanguageIcon />
                  </IconButton>
                </Grid>

                <Grid item xs={4}>
                  <IconButton
                    onClick={signOut}
                    style={{
                      color: colors.orangeAccent[500],
                      textDecoration: "none",
                      // color: "inherit",
                    }}
                  >
                    <LogoutOutlinedIcon />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Box>
        </Menu>
      </Sidebar>
    </div>
  );
};

export default Warbar;
