import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { isValidIBAN } from "ibantools";
import IBAN from "iban";

export let ibanValue;
export let ibanError;

const IBANInput = () => {
  const [iban, setIban] = useState("");
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  // Function to format IBAN (using iban library or custom implementation)
  const formatIBAN = (input) => {
    return IBAN.printFormat(input, " ");
  };

  const handleInputChange = (event) => {
    const input = event.target.value.toUpperCase().replace(/ /g, "");

    // Block input after first two characters if they are not letters
    if (input.length > 2 && !/^[0-9]+$/.test(input.slice(2))) {
      return;
    }

    // Limit to maximum 26 characters
    if (input.length > 26) {
      return;
    }

    setIban(formatIBAN(input)); // Assuming you have a formatIBAN function
    setError(!isValidIBAN(input));

    ibanValue = formatIBAN(input);
    ibanError = !isValidIBAN(input);
  };

  return (
    <div>
      <TextField
        label="IBAN"
        id="outlined-iban"
        variant="outlined"
        color="secondary"
        inputProps={{ style: { fontSize: 16 } }}
        name="iban"
        value={iban}
        onChange={handleInputChange}
        error={error}
        helperText={error ? t("Invalid IBAN") : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">IBAN:</InputAdornment>
          ),
        }}
        fullWidth
        // className={classes.textField}
      />
    </div>
  );
};

export default IBANInput;
