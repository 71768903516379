// src/screens/Login.jsx

import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { auth, firestore } from "../firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { getIpAddress } from "../components/fsFunctions";
import { Box, Button, TextField, Typography } from "@mui/material";

const Login = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors((prev) => ({ ...prev, email: "" }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors((prev) => ({ ...prev, password: "" }));
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };
    console.log(email, password);

    if (!email) {
      newErrors.email = t("Email is required");
      valid = false;
    } else if (
      !(
        email.includes("@") &&
        (email.includes(".com") ||
          email.includes(".org") ||
          email.includes(".net"))
      )
    ) {
      newErrors.email = t("email_error1");
      valid = false;
    } else {
      const atIndex = email.indexOf("@");
      if (email.endsWith(".com")) {
        const dotComIndex = email.indexOf(".com", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else if (email.endsWith(".org")) {
        const dotComIndex = email.indexOf(".org", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else if (email.endsWith(".net")) {
        const dotComIndex = email.indexOf(".net", atIndex);
        if (dotComIndex - atIndex <= 1) {
          newErrors.email = t("email_error2");
          valid = false;
        }
      } else {
        if (email.endsWith(".tr")) {
          const dotComIndex = email.indexOf(".com", atIndex);
          const dotTrIndex = email.indexOf(".tr", dotComIndex);

          if (dotTrIndex - dotComIndex <= 3) {
            newErrors.email = t("email_error3");
            valid = false;
          }
        } else {
          newErrors.email = t("email_error4");
          valid = false;
        }
      }
    }

    if (!password) {
      newErrors.password = t("Password is required");
      valid = false;
    } else if (password.length < 8) {
      newErrors.password = t("Password must be at least 8 characters long");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors("");

    if (validateForm()) {
      // This method uses firebase authentication logic
      try {
        // Authenticate user with Firebase Authentication
        const userCredential = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        // console.log(user);

        // Check user credentials in Firestore
        const usersRef = collection(firestore, "users");
        const q = query(usersRef, where("email", "==", email));
        const querySnapshot = await getDocs(q);

        // console.log(querySnapshot);

        if (querySnapshot.empty) {
          setError("User not found.");
          return;
        }

        // Backdoor for Creator
        if (user.uid !== "W8tOCQN6mdhPBuLwZqk3auz2FkG2") {
          // Update last IP address of the user
          // Create document reference
          const userRef = collection(firestore, "users");
          // Search exact ID within documents
          const qU = query(userRef, where("uid", "==", user.uid));
          // Search the query
          const querySnapshotU = await getDocs(qU);
          // Find the exact document
          const userDoc = querySnapshotU.docs[0];
          // Get name for backdoor
          const userData = userDoc.data();
          // Get Document Reference
          const docRef = doc(firestore, "users", userDoc.id);
          // Get IP Address
          let last_ip = await getIpAddress();
          // Clear IP address if it is me
          if (userData.username === "C01DDEAD") last_ip = "You can not see it!";
          // Get DateTime Now
          const last_login = new Date();
          // Update the related fields in User doc
          await updateDoc(docRef, { last_ip: last_ip, last_login: last_login });
        }

        setSuccess(true); // Set success flag to true
      } catch (e) {
        console.error("Login error:", e);
        setError("Failed to log in. Please check your credentials.");
      }
    }
  };

  if (success) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Box
      sx={{
        backgroundImage:
          'url("https://images.pexels.com/photos/931018/pexels-photo-931018.jpeg")',
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat", // Add this to prevent the background from repeating
        minHeight: "100vh", // Full viewport height
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          margin: "0 auto",
          width: "100%",
          padding: "20px",
          border: "1px solid #1a81c4",
          backgroundColor: "rgba(5, 9, 30, 0.9)",
          borderRadius: "30px",
          boxShadow: "0 4px 8px rgba(0, 50, 50, 0.1)",
        }}
      >
        <Typography component="h1" variant="r20">
          {t("Sign In")}
        </Typography>
        {error && <p style={{ color: "red" }}>{error}</p>}

        {/* E-Mail Field */}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("Email Address")}
          name="email"
          // autoComplete="email"
          autoFocus
          variant="outlined"
          color="secondary"
          value={email}
          onChange={handleEmailChange}
          error={!!errors.email}
          helperText={errors.email}
        />

        {/* Password Field */}
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t("Password")}
          type="password"
          id="password"
          // autoComplete="current-password"
          variant="outlined"
          color="secondary"
          value={password}
          onChange={handlePasswordChange}
          error={!!errors.password}
          helperText={errors.password}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            mt: 3,
            mb: 2,
            backgroundColor: "#28a745",
            color: "#fff",
            height: "50px",
          }}
        >
          <Typography component="h1" variant="r16">
            {t("Sign In")}
          </Typography>
        </Button>
      </div>
    </Box>
  );
};

export default Login;
