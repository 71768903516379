// src/App.js

// DONE Yetkiye göre sayfa / obje görme
// DONE Kullanıcı ekleme/güncelleme sayfaları
// DONE Hesap ekleme/güncelleme sayfaları
// DONE Hesabı pasife alınca aktif hesap yoksa sayfaya uyarı düşülecek
// DONE IBAN'a özel girdi şekli
// DONE Table joint and push to new table
// DONE Rapor ve havuz sayfaları.
// DONE Rapor ve havuz sayfalarında tarihe, siteye ve yatırım/çekim işlem türüne göre arama yapılabilmeli
// DONE İleriye dönük, acelesi yok. Query ile Optimizasyon.
// DONE Tüm girdi kısımlarına sınırlayıcı ve kontrol mekanizmaları yapılacak
// DONE Son olarak post methoduna göre entegrasyon yapılacak.
// DONE Banka ekleme sayfasında Combobox'a bankları DB'den yükleme.
// c01ddead@ultrahavale.com m4u}K[rVT_n`sx!jP".E^]7'(*J=fN5-

import { useState } from "react";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider, useAuth } from "./components/AuthContext";
import { DataProvider } from "./components/DataProvider";
import Warbar from "./components/Warbar";
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Team from "./screens/Team";
import CurrentInvestments from "./screens/CurrentInvestments";
import CurrentWithdrawals from "./screens/CurrentWithdrawals";
import AllInvestments from "./screens/AllInvestments";
import AllWithdrawals from "./screens/AllWithdrawals";
import Cancelled from "./screens/Cancelled";
import BankAccounts from "./screens/BankAccounts";
import FirmsAndSites from "./screens/FirmsAndSites";
import SignUp from "./screens/SignUp";
import "./App.css";
import Test from "./screens/Test";

const App = () => {
  const [theme, colorMode] = useMode();
  const [isSidebar] = useState(true);

  return (
    <AuthProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <DataProvider>
            <Router>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <RedirectToDashboardIfLoggedIn>
                      <Login />
                    </RedirectToDashboardIfLoggedIn>
                  }
                />

                <Route
                  path="/signup"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <SignUp />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <Dashboard />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/team"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <Team />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/investments"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <CurrentInvestments />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/withdrawals"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <CurrentWithdrawals />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/all_investments"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <AllInvestments />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/all_withdrawals"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <AllWithdrawals />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/cancelled"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <Cancelled />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/bankaccounts"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                          <BankAccounts />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/firmsandsites"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          <FirmsAndSites />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                {/* FOR TEST PURPOSE DELETE LATER */}
                <Route
                  path="/test"
                  element={
                    <PrivateRoute>
                      <div className="app">
                        <Warbar isSidebar={isSidebar} />
                        <main className="content">
                          <Test />
                        </main>
                      </div>
                    </PrivateRoute>
                  }
                />

                <Route path="*" element={<Navigate to="/login" />} />
              </Routes>
            </Router>
          </DataProvider>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </AuthProvider>
  );
};

const RedirectToDashboardIfLoggedIn = ({ children }) => {
  const { currentUser } = useAuth();
  return currentUser ? <Navigate to="/dashboard" /> : children;
};

export default App;
