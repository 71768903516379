// src/components/fsFunctions.jsx

import {
  addDoc,
  getDoc,
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import { firestore, storage, auth } from "../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword } from "firebase/auth";
import bcrypt from "bcryptjs";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const getIpAddress = async () => {
  try {
    const response = await axios.get("https://api.ipify.org?format=json");
    return response.data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

// Function to get all banks from firestore
export async function getAllBanks() {
  try {
    // Reference to the all documents in the "banks" collection
    const querySnapshot = await getDocs(collection(firestore, "banks"));

    const bankData = [];
    // const prefixData = [];
    // const typeData = [];

    // Fetch the document
    // biome-ignore lint/complexity/noForEach: <explanation>
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      bankData.push(data.bank);
      // prefixData.push(data.prefix);
      // typeData.push(data.type);
    });

    return bankData;
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

// Function to get all firms from firestore
export async function getAllFirms() {
  try {
    // Reference to the all documents in the "banks" collection
    const querySnapshot = await getDocs(collection(firestore, "firms"));

    const firmData = [];
    // const prefixData = [];
    // const typeData = [];

    // Fetch the document
    // biome-ignore lint/complexity/noForEach: <explanation>
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      firmData.push(data.firm);
      // prefixData.push(data.prefix);
      // typeData.push(data.type);
    });

    return firmData;
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

// Function to get all sites from firestore
export async function getAllSites() {
  try {
    // Reference to the all documents in the "banks" collection
    const querySnapshot = await getDocs(collection(firestore, "sites"));

    const siteData = [];
    // const prefixData = [];
    // const typeData = [];

    // Fetch the document
    // biome-ignore lint/complexity/noForEach: <explanation>
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      siteData.push(data.site);
      // prefixData.push(data.prefix);
      // typeData.push(data.type);
    });

    return siteData;
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

// Function to get Firestore document by UID
export async function getUserDataByUID(uid) {
  try {
    // Reference to the document in the "users" collection with the specific UID
    const docRef = doc(firestore, "users", uid);

    // Fetch the document
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Document data is available
      // console.log("Document data:", docSnap.data());
      return docSnap.data();
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      // No such document exists
      // console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
  }
}

// Function to delete user document from Firestore collection by UID
export async function deleteUserByUID(uid) {
  try {
    // Reference to the document in the "users" collection with the specific UID
    const docRef = doc(firestore, "users", uid);
    // const docSnap = await getDoc(docRef);
    // const au_uid = docSnap.data();
    await deleteDoc(docRef);
    // console.log("User deleted from Firestore.");
    // auth().deleteUser(au_uid.uid);
    // console.log("User deleted from Firebase.");
    return 0;
  } catch (error) {
    console.error("Error getting document:", error);
    return 19;
  }
}

// Function to add user to Firestore collection
export async function addUserData(userData, profilePicture) {
  try {
    // Check if the email already exists in Firestore
    const usersRef = collection(firestore, "users");
    const q = query(usersRef, where("email", "==", userData.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.error("User with this email already exists.");
      return 1;
    }

    // Create a new user in Firebase Authentication
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userData.email,
      userData.password
    );

    const user = userCredential.user;
    let profilePictureURL = "";

    // Upload profile picture if it exists
    if (profilePicture) {
      const cleanedName = userData.username.replace(/\s+/g, "_").toLowerCase(); // Convert spaces to underscores and make lowercase
      const extension = profilePicture.name.split(".").pop(); // Get the file extension
      const profilePictureRef = ref(
        storage,
        `profilePictures/${cleanedName}.${extension}`
      );
      await uploadBytes(profilePictureRef, profilePicture);
      profilePictureURL = await getDownloadURL(profilePictureRef);
    }

    // Hash the new password
    const hashedPassword = await bcrypt.hash(userData.password, 16);

    // Add new user document to Firestore
    // const docRef =
    await addDoc(usersRef, {
      uid: user.uid, // Store the user's UID from Firebase Authentication
      username: userData.username,
      email: userData.email,
      auth: userData.auth,
      profilePictureURL: profilePictureURL,
      password: hashedPassword,
      process: "",
      site: userData.site,
      last_login: new Date(),
      last_ip: "",
      firm: userData.firm,
    });

    // console.log("Document written with ID: ", docRef.id);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 2;
  }
}

// Function to update user Firestore document by UID
export const updateUserData = async (uid, userData, profilePicture) => {
  const docRef = doc(firestore, "users", uid);
  const userDoc = await getDoc(docRef);

  if (!userDoc.exists()) {
    console.error("User does not exist");
    return 3;
  }

  // console.log(userDoc.data());
  const currentData = userDoc.data();
  const updates = {};

  // Compare current data with new data to find changes
  for (const key in userData) {
    if (userData[key] !== currentData[key]) {
      updates[key] = userData[key];
    }
  }

  let profilePictureURL = "";

  // Upload profile picture if it exists
  if (profilePicture) {
    const cleanedName = userData.username.replace(/\s+/g, "_").toLowerCase(); // Convert spaces to underscores and make lowercase
    const extension = profilePicture.name.split(".").pop(); // Get the file extension
    const profilePictureRef = ref(
      storage,
      `profilePictures/${cleanedName}.${extension}`
    );
    await uploadBytes(profilePictureRef, profilePicture);
    profilePictureURL = await getDownloadURL(profilePictureRef);
    updates.profilePictureURL = profilePictureURL;
  } else {
    if (userData.profilePictureURL === "")
      updates.profilePictureURL = currentData.profilePictureURL;
  }

  // Only update if there are changes
  if (Object.keys(updates).length > 0) {
    // Hash the new password
    let hashedPassword;
    if (userData.password !== "") {
      hashedPassword = await bcrypt.hash(userData.password, 16);
      updates.password = hashedPassword;
    }

    // console.log(updates);

    try {
      await updateDoc(docRef, updates);
      // console.log("User updated successfully");
      return 0;
    } catch (error) {
      console.error("Error updating user: ", error);
      return 4;
    }
  } else {
    // console.log("No changes detected");
    return 0;
  }
};

// Function to add bank account to Firestore collection
export async function addBankAccount(bankData, ibanValue, logo) {
  try {
    // Check if the email already exists in Firestore
    const banksRef = collection(firestore, "bank_accounts");
    const q = query(banksRef, where("iban", "==", bankData.iban));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.error("Bank with this IBAN already exists.");
      return 5;
    }

    let logoURL = "";

    // Upload profile picture if it exists
    if (logo) {
      const cleanedName = bankData.bank.replace(/\s+/g, "_").toLowerCase(); // Convert spaces to underscores and make lowercase
      const extension = logo.name.split(".").pop(); // Get the file extension
      const logoRef = ref(
        storage,
        `profilePictures/${cleanedName}.${extension}`
      );
      await uploadBytes(logoRef, logo);
      logoURL = await getDownloadURL(logoRef);
    }

    const limits = [bankData.limit_min, bankData.limit_max];

    // Add new user document to Firestore
    // const docRef =
    await addDoc(banksRef, {
      added: new Date(),
      bank: bankData.bank, // Store the user's UID from Firebase Authentication
      card_holder: bankData.card_holder,
      firm: bankData.firm,
      iban: ibanValue,
      limits: limits,
      logoURL: logoURL,
      process: "Passive",
      transaction_limit: bankData.transaction_limit,
    });
    // console.log("Document written with ID: ", docRef.id);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 6;
  }
}

// Function to delete bank document from Firestore collection by UID
export async function deleteBankAccountByIBAN(iban) {
  const usersRef = collection(firestore, "bank_accounts");
  const q = query(usersRef, where("iban", "==", iban));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.error("Bank account does not exist");
    return 7;
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    const userDoc = querySnapshot.docs[0];
    try {
      // Reference to the document in the "users" collection with the specific UID
      const docRef = doc(firestore, "bank_accounts", userDoc.id);
      await deleteDoc(docRef);
      // console.log("Account with IBAN deleted: ", iban);
      return 0;
    } catch (error) {
      console.error("Error getting document:", error);
      return 8;
    }
  }
}

// Function to update user Firestore document by UID
export async function toggleBankStatus(iban, status) {
  // Check if the email already exists in Firestore
  const usersRef = collection(firestore, "bank_accounts");
  const q = query(usersRef, where("iban", "==", iban));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.error("Bank account does not exist");
    return 9;
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    // Cancelled due boss' request
    // // First Passivate the current Active account
    // const qP = query(usersRef, where("process", "==", "Active"));
    // const querySnapshotP = await getDocs(qP);
    // // Check if there are any active account
    // if (querySnapshotP.empty) {
    //   console.error("Active bank account does not exist");
    // } else {
    //   const userDocP = querySnapshotP.docs[0];
    //   const currentDataP = userDocP.data();
    //   currentDataP.process = "Passive";
    //   const refP = doc(firestore, "bank_accounts", userDocP.id);
    //   await updateDoc(refP, currentDataP);
    // }
    // Then process the new query
    const userDoc = querySnapshot.docs[0];
    const currentData = userDoc.data();

    // Only update status
    if (currentData.process === status) {
      // console.log("No changes detected");
      return 0;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      currentData.process = status;

      try {
        const ref = doc(firestore, "bank_accounts", userDoc.id);
        await updateDoc(ref, currentData);
        // console.log("Bank account updated successfully");
        return 0;
      } catch (error) {
        console.error("Error updating bank account: ", error);
        return 10;
      }
    }
  }
}

// Function to add Firm to Firestore collection
export async function addFirm(firmData) {
  const id = uuidv4();
  try {
    // Check if the email already exists in Firestore
    const firmsRef = collection(firestore, "firms");
    const q = query(firmsRef, where("firm", "==", firmData));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.error("Firm with this name already exists.");
      return 5;
    }

    const apiId = uuidv4();
    const apiKey = uuidv4();

    const hashedApiKey = await bcrypt.hash(apiKey, 10);

    // Add new user document to Firestore
    // const docRef =
    await addDoc(firmsRef, {
      id: id,
      firm: firmData,
      process: "Passive",
      apiID: apiId,
      apiKey: apiKey,
      apiKeyHashed: hashedApiKey,
    });

    console.log("API ID: ", apiId, " API Key: ", apiKey);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 6;
  }
}

// Function to delete firm document from Firestore collection by ID
export async function deleteFirm(id) {
  try {
    // Check if the email already exists in Firestore
    const firmsRef = collection(firestore, "firms");
    const q = query(firmsRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const firmDoc = querySnapshot.docs[0];
    // Reference to the document in the "users" collection with the specific UID
    const docRef = doc(firestore, "firms", firmDoc.id);
    await deleteDoc(docRef);
    // console.log("Firm deleted from Firestore.");
    return 0;
  } catch (error) {
    console.error("Error getting document:", error);
    return 19;
  }
}

// Function to add Site to Firestore collection
export async function addSite(siteData) {
  const id = uuidv4();
  try {
    // Check if the email already exists in Firestore
    const sitesRef = collection(firestore, "sites");
    const q = query(sitesRef, where("site", "==", siteData));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.error("Site with this name already exists.");
      return 5;
    }

    const apiId = uuidv4();
    const apiKey = uuidv4();

    const hashedApiKey = await bcrypt.hash(apiKey, 10);

    // Add new user document to Firestore
    // const docRef =
    await addDoc(sitesRef, {
      id: id,
      site: siteData,
      process: "Passive",
      apiID: apiId,
      apiKey: apiKey,
      apiKeyHashed: hashedApiKey,
    });

    console.log("API ID: ", apiId, " API Key: ", apiKey);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 6;
  }
}

// Function to delete site document from Firestore collection by ID
export async function deleteSite(id) {
  try {
    // Check if the email already exists in Firestore
    const firmsRef = collection(firestore, "sites");
    const q = query(firmsRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const siteDoc = querySnapshot.docs[0];
    // Reference to the document in the "users" collection with the specific UID
    const docRef = doc(firestore, "sites", siteDoc.id);
    await deleteDoc(docRef);
    // console.log("Site deleted from Firestore.");
    return 0;
  } catch (error) {
    console.error("Error getting document:", error);
    return 19;
  }
}

// Function to update user Firestore document by UID
export async function toggleFirmStatus(id, status) {
  // Check if the email already exists in Firestore
  const firmsRef = collection(firestore, "firms");
  const q = query(firmsRef, where("id", "==", id));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.error("Firm does not exist");
    return 20;
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    // Then process the new query
    const firmDoc = querySnapshot.docs[0];
    const currentData = firmDoc.data();

    // Only update status
    if (currentData.process === status) {
      // console.log("No changes detected");
      return 0;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      currentData.process = status;

      try {
        const ref = doc(firestore, "firms", firmDoc.id);
        await updateDoc(ref, currentData);
        // console.log("Bank account updated successfully");
        return 0;
      } catch (error) {
        console.error("Error updating firm status: ", error);
        return 21;
      }
    }
  }
}

// Function to update user Firestore document by UID
export async function toggleSiteStatus(id, status) {
  // Check if the email already exists in Firestore
  const SitesRef = collection(firestore, "sites");
  const q = query(SitesRef, where("id", "==", id));
  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    console.error("Site does not exist");
    return 22;
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    // Then process the new query
    const siteDoc = querySnapshot.docs[0];
    const currentData = siteDoc.data();

    // Only update status
    if (currentData.process === status) {
      // console.log("No changes detected");
      return 0;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else {
      currentData.process = status;

      try {
        const ref = doc(firestore, "sites", siteDoc.id);
        await updateDoc(ref, currentData);
        // console.log("Bank account updated successfully");
        return 0;
      } catch (error) {
        console.error("Error updating site status: ", error);
        return 23;
      }
    }
  }
}

// Function to move investment entry from waiting to done pool
export async function investProcessDone(investData, userData) {
  try {
    // Get invest data via ID
    const invsRef = collection(firestore, "invests_w");
    const q = query(invsRef, where("id", "==", investData));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error("There is no entry with this ID.");
      return 11;
    }

    // Get document
    const invDoc = querySnapshot.docs[0];
    // Get data from document
    const invData = invDoc.data();

    // Merge tables and create new data for pool
    const mergedData = {
      ...invData, // Keep original data
      confirmed_by: userData.username,
      process_date: new Date(),
    };

    // Reference to the new document
    const invsAllRef = doc(collection(firestore, "investments_all"));
    // Write the merged data to the new document
    // const docRef =
    await setDoc(invsAllRef, mergedData);

    // Create a new data to save in reports
    const reportRef = collection(firestore, "reports");
    const qR = query(reportRef, where("site", "==", invData.site));
    const querySnapshotR = await getDocs(qR);

    let currentValue;
    let id;
    let repDoc;
    let reportsDoc;

    if (!querySnapshotR.empty) {
      // Get document
      repDoc = querySnapshotR.docs[0];
      // console.log(repDoc);
      // Get data from document
      const repData = repDoc.data();
      // console.log(repData);
      reportsDoc = doc(firestore, "reports", repDoc.id);
      currentValue = Number(repData.total_invest_value);
      id = repData.id;
    } else {
      currentValue = 0;
      id = uuidv4();
      reportsDoc = doc(collection(firestore, "reports"));
    }

    const updatedValue = currentValue + Number(invData.value);

    const newData = {
      id: id,
      site: invData.site,
      total_invest_value: updatedValue,
    };

    // Reference to the new document
    await setDoc(reportsDoc, newData, { merge: true });

    // console.log("Document written with ID: ", docRef.id);
    const docRef = doc(firestore, "invests_w", invDoc.id);
    // Delete entry from original table after merge complete
    await deleteDoc(docRef);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 12;
  }
}

// Function to move investment entry from waiting to cancelled pool
export async function investProcessCancelled(
  investData,
  userData,
  cancelReason
) {
  // console.log(investData);
  try {
    // Get invest data via ID
    const invsRef = collection(firestore, "invests_w");
    const q = query(invsRef, where("id", "==", investData));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error("There is no entry with this ID.");
      return 13;
    }

    // Get document
    const invDoc = querySnapshot.docs[0];
    // Get data from document
    const invData = invDoc.data();

    // Merge tables and create new data for pool
    const mergedData = {
      ...invData, // Keep original data
      cancellation_reason: cancelReason, // Add new field
      cancelled_by: userData.username,
      transaction_type: "Investment",
      process_date: new Date(),
    };

    // Reference to the new document
    const cancelledRef = doc(collection(firestore, "cancelled"));
    // Write the merged data to the new document
    // const docRef =
    await setDoc(cancelledRef, mergedData);
    // console.log("Document written with ID: ", docRef.id);
    const docRef = doc(firestore, "invests_w", invDoc.id);
    // Delete entry from original table after merge complete
    await deleteDoc(docRef);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 14;
  }
}

// Function to move withdrawal entry from waiting to done pool
export async function withdrawalProcessDone(investData, userData) {
  try {
    // Get invest data via ID
    const invsRef = collection(firestore, "withdrawals_w");
    const q = query(invsRef, where("id", "==", investData));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error("There is no entry with this ID.");
      return 15;
    }

    // Get document
    const witDoc = querySnapshot.docs[0];
    // Get data from document
    const witData = witDoc.data();

    // Merge tables and create new data for pool
    const mergedData = {
      ...witData, // Keep original data
      confirmed_by: userData.username,
      process_date: new Date(),
    };

    // Reference to the new document
    const withsAllRef = doc(collection(firestore, "withdrawals_all"));
    // Write the merged data to the new document
    // const docRef =
    await setDoc(withsAllRef, mergedData);

    // Create a new data to save in reports
    const reportRef = collection(firestore, "reports");
    const qR = query(reportRef, where("site", "==", witData.site));
    const querySnapshotR = await getDocs(qR);

    let currentValue;
    let id;
    let repDoc;
    let reportsDoc;

    if (!querySnapshotR.empty) {
      // Get document
      repDoc = querySnapshotR.docs[0];
      // console.log(repDoc);
      // Get data from document
      const repData = repDoc.data();
      // console.log(repData);
      reportsDoc = doc(firestore, "reports", repDoc.id);
      currentValue = Number(repData.total_withdrawal_value);
      id = repData.id;
    } else {
      currentValue = 0;
      id = uuidv4();
      reportsDoc = doc(collection(firestore, "reports"));
    }

    const updatedValue = currentValue + Number(witData.value);

    const newData = {
      id: id,
      site: witData.site,
      total_withdrawal_value: updatedValue,
    };

    // Reference to the new document
    await setDoc(reportsDoc, newData, { merge: true });

    const docRef = doc(firestore, "withdrawals_w", witDoc.id);
    // Delete entry from original table after merge complete
    await deleteDoc(docRef);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 16;
  }
}

// Function to move withdrawal entry from waiting to cancelled pool
export async function withdrawalProcessCancelled(
  investData,
  userData,
  cancelReason
) {
  try {
    // Get invest data via ID
    const invsRef = collection(firestore, "withdrawals_w");
    const q = query(invsRef, where("id", "==", investData));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error("There is no entry with this ID.");
      return 17;
    }

    // Get document
    const witDoc = querySnapshot.docs[0];
    // Get data from document
    const witData = witDoc.data();

    // Merge tables and create new data for pool
    const mergedData = {
      ...witData, // Keep original data
      cancellation_reason: cancelReason, // Add new field
      cancelled_by: userData.username,
      transaction_type: "Withdrawal",
      process_date: new Date(),
    };

    // Reference to the new document
    const cancelledRef = doc(collection(firestore, "cancelled"));
    // Write the merged data to the new document
    // const docRef =
    await setDoc(cancelledRef, mergedData);
    // console.log("Document written with ID: ", docRef.id);
    const docRef = doc(firestore, "withdrawals_w", witDoc.id);
    // Delete entry from original table after merge complete
    await deleteDoc(docRef);
    return 0;
  } catch (e) {
    console.error("Error adding document: ", e);
    return 18;
  }
}
