// src/screens/BankAccounts.jsx

import React, { useState } from "react";
import { useAuth } from "../components/AuthContext";
import { DataGrid } from "@mui/x-data-grid";
import CustomLinearProgress, {
  CustomToolbar,
  StatusChip,
  OvalButton,
  CustomMessage,
} from "../components/Common";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Header from "../components/Header";
import { useTranslation } from "react-i18next";
import Modal from "../components/Modal";
import { toggleBankStatus } from "../components/fsFunctions";
import { useData } from "../components/DataProvider";

const BankAccounts = () => {
  const { currentUser } = useAuth();
  const { bankAccounts, totalActiveAccount, loading } = useData();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [openMessage, setOpenMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line
  const [bankID, setBankID] = useState(null);
  const [caller, setCaller] = useState(null);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCreate = () => {
    // console.log("Creating new bank account");
    setCaller("accounts-add");
    setIsModalOpen(true);
  };

  const handleEdit = (id, status) => {
    // console.log(`Editing item with ID: ${id}`);
    if (status === "Passive") toggleBankStatus(id, "Active");
    else toggleBankStatus(id, "Passive");
  };

  const handleDelete = (id, status) => {
    // console.log(`Deleting item with IBAN: ${id}`);
    if (status === "Passive") {
      setCaller("account-delete");
      setBankID(id);
      setIsModalOpen(true);
    } else {
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "card_holder",
      headerName: t("Card Holder"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 120,
    },
    {
      field: "iban",
      headerName: t("IBAN"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "arrayField",
      headerName: t("Min/Max Limit"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 300,
    },
    {
      field: "transaction_limit",
      headerName: t("Transaction Limit"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 150,
    },
    {
      field: "bank",
      headerName: t("Bank"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: (params) => <StatusChip {...params} />,
    },
    {
      field: "firm",
      headerName: t("Firm"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 200,
    },
    {
      field: "process",
      headerName: t("Process"),
      flex: 1,
      headerAlign: "center",
      align: "center",
      width: 100,
      renderCell: (params) => (
        <div>
          {currentUser.auth !== "Guest" && (
            <>
              <OvalButton
                label={
                  params.row.process === "Passive"
                    ? t("Activate")
                    : t("Passivate")
                }
                color={params.row.process === "Passive" ? "#F19729" : "#1BCE13"}
                onClick={() => handleEdit(params.row.iban, params.row.process)}
              />

              {params.row.process === "Passive" && (
                <OvalButton
                  label={t("Delete")}
                  color="#f44336"
                  onClick={() =>
                    handleDelete(params.row.iban, params.row.process)
                  }
                />
              )}
            </>
          )}
          {currentUser.auth === "Guest" && <div>No action available</div>}
        </div>
      ),
    },
  ];

  const handleFeedback = (feedback) => {
    setMessage(feedback);
    setOpenMessage(true);
  };

  const handleMessageClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenMessage(false);
  };

  if (currentUser.auth !== "Guest") {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="15px"
        >
          <Header
            title={t("Bank Accounts")}
            subtitle={t("All bank accounts")}
          />

          {isModalOpen && (
            <Modal
              userID={currentUser}
              onClose={closeModal}
              extParam={bankID}
              caller={caller}
              onFeedback={handleFeedback}
            />
          )}

          <CustomMessage
            open={openMessage}
            onClose={handleMessageClose}
            message={message}
          />
          {totalActiveAccount < 1 && (
            <Box
              alignItems="center"
              p={2}
              sx={{ border: "2px solid red", borderRadius: 2, color: "red" }}
            >
              {t("There is no active bank account! Please activate one!")}
            </Box>
          )}

          <Box>
            <Button
              onClick={handleCreate}
              sx={{
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
                margin: "0 10px 0 0",
                ...(theme.palette.mode === "dark"
                  ? {
                      backgroundColor: "#13AD23",
                    }
                  : { backgroundColor: "#2BDF28" }),
              }}
            >
              <LibraryAddIcon sx={{ mr: "10px" }} />
              {t("Add New Account")}
            </Button>
          </Box>
        </Box>

        <Box
          m="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          //height="100vh" /* Full height of the viewport */
          min-height="0" /* Ensure it can shrink */
          width="99%"
          sx={{
            "& .MuiDataGrid-root": {
              fontSize: "16px",
              ...(theme.palette.mode === "dark"
                ? {
                    border: "1px solid rgba(62, 67, 150, 1)",
                  }
                : { border: "1px solid rgba(232, 93, 4, 1)" }),
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
              fontSize: "16px",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
              fontSize: "1.2rem",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              ...(theme.palette.mode === "dark"
                ? {
                    backgroundColor: colors.blueAccent[700],
                  }
                : { backgroundColor: colors.orangeAccent[800] }),
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-cell:hover": {
              ...(theme.palette.mode === "dark"
                ? {
                    color: colors.blueAccent[500],
                  }
                : { color: colors.orangeAccent[500] }),
            },
          }}
        >
          <DataGrid
            columnVisibilityModel={{
              // Hide columns status and traderName, the other columns will remain visible
              id: false,
            }}
            checkboxSelection={false}
            rows={bankAccounts}
            columns={columns}
            slots={{
              toolbar: CustomToolbar,
              loadingOverlay: CustomLinearProgress,
            }}
            loading={loading}
            pageSize={5}
            autoHeight
            autoWidth
            autoPageSize
            disableSelectionOnClick
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Box>
    );
    // biome-ignore lint/style/noUselessElse: <explanation>
  } else {
    return (
      <Box m="20px">
        {/* HEADER */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          paddingRight="15px"
        >
          <Header
            title={t("403")}
            subtitle={t("You don't have permission to see this page")}
          />
        </Box>
      </Box>
    );
  }
};

export default BankAccounts;
